<template>
  <div class="pageContol listWrap templateList1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">试卷管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">试卷列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="试卷名称" class="searchboxItem ci-full">
              <span class="itemLabel">试卷名称:</span>
              <el-input v-model="retrievalData.courseName" type="text" size="small" placeholder="请输入试卷名称" clearable />
            </div>
            <div title="题库名称" class="searchboxItem ci-full">
              <span class="itemLabel">题库名称:</span>
              <el-input v-model="retrievalData.courseName" type="text" size="small" placeholder="请输入题库名称" clearable />
            </div>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round icon="el-icon-plus" @click="newBuilt()">新建</el-button>
          </div>
        </div> -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="pTitle">试卷名称：{{ ruleName }}</div>
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="试卷名称" align="left" show-overflow-tooltip fixed prop="paperName" width="300px">
                <template slot-scope="scope">
                  <div v-if="!scope.row.nameShow">
                    <span> {{ scope.row.paperName }}</span>
                    <i class="el-icon-edit" style="margin-left: 10px;"
                      @click="$set(tableData[scope.$index], 'nameShow', true)"></i>
                  </div>
                  <el-input v-else v-model="scope.row.paperName" :key="scope.row.paperId" maxlength="30" size="mini"
                    show-word-limit autofocus @blur="() => {
                        editRowName(scope.row);
                      }
                      "></el-input>
                </template>
              </el-table-column>
              <el-table-column label="期望试题数量" align="left" show-overflow-tooltip prop="expectedQuestionNum"
                minWidth="150px">
                <template slot-scope="scope">
                  {{
                    scope.row.compositionType === '0' ? scope.row.expectedQuestionNum : '--'
                  }}
                </template>
              </el-table-column>
              <el-table-column label="试题数量" align="left" show-overflow-tooltip prop="questionNum" minWidth="150px" />
              <el-table-column label="试卷分数" align="left" show-overflow-tooltip prop="score" minWidth="150px" />
              <el-table-column label="更新时间" align="left" show-overflow-tooltip prop="createTime" minWidth="200">
                <template slot-scope="scope">
                  {{
                    scope.row.createTime | momentDate
                  }}
                </template>
              </el-table-column>
              <el-table-column label="试卷编号" align="center" min-width="200">
                <template slot-scope="scope">
                  <div v-if="!scope.row.orderShow">
                    <span> {{ scope.row.paperCode }}</span>
                    <i class="el-icon-edit" style="margin-left: 10px;"
                      @click="$set(tableData[scope.$index], 'orderShow', true)"></i>
                  </div>
                  <el-input v-else v-model="scope.row.paperCode" :key="scope.row.paperId" maxlength="30" size="mini"
                    show-word-limit autofocus @blur="() => {
                        editRowOrder(scope.row);
                      }
                      "></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="200px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="copyPaper(scope.row)">复制</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="addEditexaminationPaper(scope.row)">编辑</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="previewExamination(scope.row)">预览</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="bulkDownloadExaminationPaperList(scope.row)">下载</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini" v-if="scope.row.compositionType === '0'"
                    @click="deleteExaminationPaperList(scope.row)">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog title="复制试卷" :visible.sync="copyDialogVisible" width="60%" :close-on-click-modal="false"
      :before-close="copyHandleClose">
      <div>
        <el-form ref="dialogForm" label-width="7rem" class="form" :rules="dialogRules" :model="dialogForm">
          <el-form-item label="试卷名称" prop="paperName">
            <el-input v-model="dialogForm.paperName" size="small" maxlength="50" show-word-limit
              style="width:100%"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="copyHandleClose">取 消</el-button>
        <el-button type="primary" @click="copyDialogSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "inkQuestionBank_examinationPaperManage_tacticsList",
  components: {
    Empty,
    PageNum
  },
  data() {
    return {
      // 试卷id
      ruleId: "",
      // 试卷名称
      ruleName: "",
      // 复制弹窗
      copyDialogVisible: false,
      dialogForm: {
        paperName: '',
        // 列表行数据
        paperId: '',
        isGroupPapers: '',
      },
      dialogRules: {
        paperName: [
          { required: true, trigger: "change", message: "请填写试卷名称" },
        ]
      },
    };
  },
  mixins: [List],
  created() {
    this.ruleId = this.$route.query.ruleId;
    this.ruleName = this.$route.query.ruleName;
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems"
    })
  },
  methods: {
    // 修改试卷名称
    editRowName(row) {
      const params = {
        paperName: row.paperName,
        paperId: row.paperId
      };
      this.$post("/biz/exam/bank/paper/name/upd", params, 3000, true, 6).then(res => {
        this.$message({
          type: "success",
          message: "修改成功"
        });
        this.getData(-1);
      });
    },
    // 修改试卷编号
    editRowOrder(row) {
      const params = {
        paperCode: row.paperCode,
        paperId: row.paperId
      };
      this.$post("/biz/exam/bank/paper/modify", params, 3000, true, 6).then(res => {
        this.$message({
          type: "success",
          message: "修改成功"
        });
        this.getData(-1);
      });
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ruleId: this.$route.query.ruleId
      };
      this.doFetch({
        url: "/biz/exam/bank/paper/pageList",
        params,
        pageNum
      }, true, 6);
    },
    // 复制 - 试卷
    copyPaper(row) {
      this.dialogForm.paperId = row.paperId
      this.dialogForm.isGroupPapers = row.isGroupPapers
      this.dialogForm.paperName = row.paperName
      this.copyDialogVisible = true
    },
    // 复制 - 弹窗关闭
    copyHandleClose() {
      this.$refs.dialogForm.resetFields();
      this.copyDialogVisible = false
    },
    // 复制 - 提交
    copyDialogSave() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          console.log('提交复制')
          this.$post("/biz/exam/bank/paper/copyPaper", { paperId: this.dialogForm.paperId, paperName: this.dialogForm.paperName }, 3000, true, 6)
            .then((res) => {
              this.$message({
                type: "success",
                message: "复制成功!",
              });
              this.getData(this.pageNum)
              this.copyHandleClose()
            })
            .catch(() => {
              return;
            });

        }
      })
    },
    // 编辑 - 试卷
    addEditexaminationPaper(row) {
      let params = {
        paperId: row.paperId,
        stu: 'edit',
      }
      if (row.compositionType == '1') {
        params.isGroupPapers = true
      }
      this.$router.push({
        path: "/inkQuestionBank/examinationPaperManage/tacticsUpdate",
        query: params
      });
    },
    // 预览
    previewExamination(row) {
      this.$router.push({
        path: "/inkQuestionBank/examinationPaperManage/tacticsUpdate",
        query: {
          paperId: row.paperId,
          stu: 'view'
        }
      });
    },
    // 下载
    bulkDownloadExaminationPaperList(row) {
      this.$post("/biz/exam/bank/extract/rule/asyncDownloadPaper", { paperId: row.paperId }, 3000, true, 6)
        .then((res) => {
          const arr = [...res.data];
          for (let item of arr) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        })
        .catch(() => {
          return;
        });
    },
    // 删除
    deleteExaminationPaperList(row) {
      this.$confirm('试卷删除后不可恢复，确认删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$post("/biz/exam/bank/paper/delete", { paperId: row.paperId }, 3000, true, 6)
          .then((res) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getData(this.pageNum);
          })
          .catch(() => {
            return;
          });
      }).catch(() => {

      });
    },
    // 列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 10;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
};
</script>

<style lang="less" scoped>
.pTitle {
  margin-bottom: 10px;
}
</style>